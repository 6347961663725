import React, { useState, useEffect } from 'react';
import { Button, message, Modal } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

interface SelectedOption {
  type: 'topup' | 'bundle';
  BundleName?: string;
  BundleProductCode?: string;
  BundleValue?: number;
  value?: number; // Used for top-up values only
}

interface LocationState {
  phoneNumber: string;
  selectedOption: {
    type: 'topup' | 'bundle';
    BundleName?: string;
    BundleProductCode?: string;
    BundleValue?: string;
    value?: number | string ; // For top-up values only
  };
  networkName: string;
  simSerialNumber?: string;
  topUpValue?: number | string ;           // For top-up values only
  bundleProductCode?: string;             // For bundles only
  bundleValue?: string;                   // For bundles only
}

export default function Confirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const { phoneNumber, selectedOption, networkName, simSerialNumber } = state;
  const [uname, setUname] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchToken = async (): Promise<string> => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", "ECH100");
    urlencoded.append("password", "Echonet8888!");
    urlencoded.append("client_id", "ffd23f9a-3ec0-4118-b5ab-16dd92706db0");
    urlencoded.append("client_secret", "SaqngdzMzZkxzK8X4ltFws9rdOjifNecVH45IaD9XbxfnU0Dzd");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow" as RequestRedirect,
    };

    try {
      const response = await fetch("https://acrp.shukran.tech/em/GenerateToken", requestOptions);
      if (!response.ok) {
        throw new Error('Failed to fetch token');
      }
      const result = await response.json();
      console.log(urlencoded);
      return result.access_token;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const token = await fetchToken();
  
      // Retrieve initial values
      let topUpValue = selectedOption.type === 'topup' 
        ? parseFloat(selectedOption.value as string) 
        : parseFloat(selectedOption.BundleValue as string);
        
      const bundleValue = selectedOption.type === 'bundle' 
        ? parseFloat(selectedOption.BundleValue as string) 
        : 0;
        
      const bundleProductCode = selectedOption.type === 'bundle' ? selectedOption.BundleProductCode || "" : "";
      const bundleName = selectedOption.type === 'bundle' ? selectedOption.BundleName || "" : "";
  
      // Apply specific condition for Vodafone with bundle type
      if (networkName === 'Vodafone' && selectedOption.type === 'bundle' && bundleValue && bundleProductCode) {
        topUpValue = 0;
      }
  
      // Prepare request body for RechargeTopUp API
      const rechargeRequestBody = {
        ContactNumber: phoneNumber,
        SIMSerialNumber: simSerialNumber || "",
        NetworkName: networkName,
        TopUpValue: topUpValue,
        BundleValue: bundleValue,
        BundleProductCode: bundleProductCode,
        UserPIN: "1111" // Hardcoded PIN for now
      };
  
      console.log('RechargeTopUp Request Body:', rechargeRequestBody);
  
      // Validation Logic for Vodafone and Other Networks
      if (networkName === 'Vodafone') {
        if ((topUpValue && (bundleProductCode || bundleValue)) || (!topUpValue && !bundleProductCode && !bundleValue)) {
          Modal.error({
            title: 'Invalid Input for Vodafone',
            content: 'For Vodafone, please provide either a top-up amount or bundle details, not both.'
          });
          setLoading(false);
          return;
        }
      } else {
        if (!topUpValue) {
          Modal.error({
            title: 'Invalid Input',
            content: 'Please provide a top-up amount for this network.'
          });
          setLoading(false);
          return;
        }
  
        // Set topUpValue to match bundleValue for other networks when it's a bundle
        if (selectedOption.type === 'bundle') {
          rechargeRequestBody.TopUpValue = bundleValue;
        }
      }
  
      // First API request: RechargeTopUp
      const rechargeResponse = await fetch("https://acrp.shukran.tech/em/RechargeTopUp", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(rechargeRequestBody)
      });
      console.log('RechargeTopUp Response:', rechargeRequestBody);
      const rechargeData = await rechargeResponse.json();
  
      // Check for error status
      if (rechargeData.Status === 0) {
        Modal.error({
          title: 'Error Processing Request',
          content: rechargeData.Message || 'An error occurred during the recharge process. Please try again.'
        });
        return; // Exit if there's an error
      }
  
      // Prepare request body for em-order-fulfillment API
      const fulfillmentRequestBody = {
        ContactNumber: phoneNumber,
        SIMSerialNumber: simSerialNumber || "",
        NetworkName: networkName,
        TopUpValue: topUpValue,
        BundleValue: bundleValue,
        BundleProductCode: bundleProductCode,
        empName: uname,
        UserPIN: "1111",
        BundleName: bundleName
      };
  
      // Second API request: em-order-fulfillment
      const fulfillmentResponse = await fetch("https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/em-order-fulfillment", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(fulfillmentRequestBody)
      });
  
      if (rechargeResponse.ok && fulfillmentResponse.ok) {
        message.success({
          content: 'Top-up/Bundle purchase successful!',
          className: 'custom-success-message',
          style: {
            fontSize: '24px',
            fontWeight: 'bold',
          }
        });
        navigate('/em-recharge');  // Navigate back to the homepage
      } else {
        message.error('Failed to process the top-up/bundle purchase.');
      }
    } catch (error) {
      console.error('Error during confirmation:', error);
      message.error('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderSelectedOption = () => {
    if (selectedOption.type === 'topup') {
      return `Top-up Amount: £${selectedOption.value}`;
    } else {
      return `Selected Bundle: ${selectedOption.BundleName || selectedOption.value}`;
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      let session = await Auth.currentUserInfo();
      console.log('User info Session', session.username);
      setUname(session.username);
    };
    fetchUser();
  }, []);

  return (
    <div style={{ textAlign: 'center', maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h1>Confirm Your Selection</h1>
      <p>{`Network: ${networkName}`}</p>
      <p>{`Phone Number: ${phoneNumber}`}</p>
      <p>{renderSelectedOption()}</p>

      <Button type="primary" onClick={handleConfirm} loading={loading} style={{ marginTop: '20px' }}>
        {loading ? "Processing..." : "Confirm and Pay"}
      </Button>
    </div>
  );
}