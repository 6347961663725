import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Table, Input, Button, Row, Col, Typography, Spin, message } from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import type { ColumnType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import axios from 'axios';

const { Title } = Typography;

interface DataType {
  [key: string]: any;
}

const ShopeeViewOrders: React.FC = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnType<DataType>[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: string,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()) ?? false,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const createColumns = useCallback((data: DataType[]): ColumnType<DataType>[] => {
    if (data.length === 0) return [];

    const allKeys = Array.from(new Set(data.flatMap(Object.keys)));
    console.log('All keys found:', allKeys);  // Debug log
    
    return allKeys.map(key => {
      const uniqueValues = Array.from(new Set(data.map(item => item[key] ?? '').filter(Boolean)));
      const column: ColumnType<DataType> = {
        title: key,
        dataIndex: key,
        key: key,
        render: (value) => value ?? '-',
        sorter: (a, b) => {
          const aValue = a[key] ?? '';
          const bValue = b[key] ?? '';
          if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue);
          }
          return (aValue as number) - (bValue as number);
        },
        ...getColumnSearchProps(key),
      };

      if (uniqueValues.length <= 10) {
        column.filters = uniqueValues.map(value => ({ text: value, value: value }));
        column.onFilter = (value, record) => record[key] === value;
      }

      return column;
    });
  }, []);

  const getData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-platform/shopee/orders/all');
      console.log('Raw API response:', response);  // Debug log

      let parsedData: DataType[];
      if (typeof response.data === 'string') {
        parsedData = JSON.parse(response.data);
      } else if (typeof response.data === 'object' && response.data !== null) {
        if (typeof response.data.body === 'string') {
          parsedData = JSON.parse(response.data.body);
        } else if (Array.isArray(response.data.body)) {
          parsedData = response.data.body;
        } else if (Array.isArray(response.data)) {
          parsedData = response.data;
        } else {
          console.error('Unexpected response format:', response.data);
          throw new Error('Unexpected response format');
        }
      } else {
        console.error('Unexpected response format:', response.data);
        throw new Error('Unexpected response format');
      }

      console.log('Parsed data:', parsedData);  // Debug log
      const orders = sortByUpdatedDate(parsedData);
      console.log('Sorted orders:', orders);  // Debug log
      setData(orders);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
      message.error('Failed to fetch orders. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const sortByUpdatedDate = (data: DataType[]): DataType[] => {
    const statusOrder: Record<string, number> = {
      READY_TO_SHIP: 0,
      PROCESSED: 1,
      SHIPPED: 2,
    };
  
    return data.sort((a, b) => {
      const statusA = statusOrder[a.Status] ?? 999;
      const statusB = statusOrder[b.Status] ?? 999;
      
      if (statusA !== statusB) {
        return statusA - statusB;
      }
      
      const dateA = new Date(a.updatedDate || 0).getTime();
      const dateB = new Date(b.updatedDate || 0).getTime();
      return dateB - dateA;
    });
  };

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (data.length > 0) {
      const newColumns = createColumns(data);
      console.log('Created columns:', newColumns);  // Debug log
      setColumns(newColumns);
    }
  }, [data, createColumns]);

  const handleGlobalSearch = (searchedVal: string) => {
    const filteredData = data.filter((item) =>
      Object.values(item).some((val) =>
        val?.toString().toLowerCase().includes(searchedVal.toLowerCase())
      )
    );
    setData(filteredData);
  };

  return (
    <>
      <Row>
        <Col span={5}>
          <Title level={3}>Shopee Orders</Title>
        </Col>
        <Col span={15}>
          <Input
            placeholder="Global Search"
            onChange={(e) => handleGlobalSearch(e.target.value)}
            style={{ width: 400, marginTop: "20px" }}
          />
        </Col>
        <Col span={4}>
          <Button
            style={{ float: 'right', margin: '5px', marginTop: '20px' }}
            type="primary"
            shape="round"
            icon={<ReloadOutlined />}
            onClick={getData}
            size="large"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        loading={{ indicator: <Spin />, spinning: loading }}
        size="small"
        bordered
        rowKey="OrderId"
      />
    </>
  );
};

export default ShopeeViewOrders;