import React from 'react';
import {
    Breadcrumb,
    Layout,
    Menu,
    theme,
    Button,
    Space,
    Checkbox,
    Form,
    Input,Card, Col, Row, Statistic
} from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export default function Home() {

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}