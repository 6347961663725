interface ContainerProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export default function Container(props: ContainerProps) {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      gap: 16,
      ...props.style
    }}>
      {props.children}
    </div>
  )
}
