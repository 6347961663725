import React from 'react';
import { Card, Col, Row } from "antd";
import o2logo from '../../assets/esim-supplier-logo/o2-logo.png';
import vodalogo from '../../assets/esim-supplier-logo/voda-logo.png';
import threelogo from '../../assets/esim-supplier-logo/three-logo.png';
import { useNavigate } from 'react-router-dom';

interface Network {
  id: number;
  name: string;
  imgSrc: string;
  topupTypes: string[];
}

const networks: Network[] = [
  { id: 1, name: 'Vodafone', imgSrc: vodalogo, topupTypes: ['credit', 'plan'] },
  { id: 2, name: 'O2', imgSrc: o2logo, topupTypes: ['credit', 'plan_and_credit'] },
  { id: 3, name: '3', imgSrc: threelogo, topupTypes: ['plan'] },
  // Add more eSIM providers as needed
];

export default function NetworkSelection() {
  const navigate = useNavigate();

  const handleSelect = (network: Network) => {
    navigate('/phone-number', { state: { networkName: network.name, imgSrc: network.imgSrc } });
  };

  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%', // Ensure full viewport height for vertical centering
          textAlign: 'center'
        }} // Center row content both horizontally and vertically
      >
        {networks.map((network) => (
          <Col key={network.id} xs={24} sm={12} md={6}>
            <Card
              hoverable
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
              cover={
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '150px',
                  }}
                >
                  <img
                    alt={network.name}
                    src={network.imgSrc}
                    style={{
                      maxHeight: '100px',
                      maxWidth: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </div>
              }
              onClick={() => handleSelect(network)}
            >
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  height: '100%',
                }}
              >
                <div style={{ fontSize: '25px', fontWeight: 'bold' }}>{network.name}</div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}