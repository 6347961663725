import React from 'react';
import {
    Breadcrumb,
    Layout,
    Menu,
    theme,
    Button,
    Space,
    Checkbox,
    Form,
    Input,Card, Col, Row, Statistic
} from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export default function HomeAdmin() {

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card bordered={false}>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24}>
                    <Card bordered={false}>
                        <iframe 
                            src="https://cloudwatch.amazonaws.com/dashboard.html?dashboard=SystemMetrics&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTI5Mjc4MDE0NzExMSIsIlUiOiJ1cy1lYXN0LTFfQWh2THBBdmRGIiwiQyI6IjFidnR0NnJkN25nbGJhcmRuYmh0ZXZqcDZrIiwiSSI6InVzLWVhc3QtMTowODFhYmVkMC0wNmFjLTQ3YzktODhhYS05MWJkNDVhZDlhNDUiLCJNIjoiUHVibGljIn0%3D"
                            title="CloudWatch Dashboard"
                            width="100%"
                            height="600px"
                            frameBorder="0"
                        ></iframe>
                    </Card>
                </Col>
            </Row>
        </>
    )
}