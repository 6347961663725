import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Space, Input, Dropdown, Button, message, Spin, Modal, Tabs, Row, Col, Typography } from 'antd';
import type { InputRef } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, UserOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import axios from 'axios'
import { tab } from '@testing-library/user-event/dist/tab';


interface DataType {
  key: React.Key;
  createdAt: string;
  oNumber: string;
  itemList: string;
  orderStatus: string;
  customerEmail: string;
  orderCode: string;
  orderProcessedDate: string;
}

interface snListType {
  key: React.Key;
  productName: string;
  productCode: string;
  snCode: string;
  productExpireDate: string;
  snPin: string;
}

interface itemListType {
  key: React.Key;
  productCode: string;
  quantity: string;
}

const itemColumns:ColumnsType<itemListType> = [
  {
    title: "Product Code",
    dataIndex: "productCode",
    key: "productCode",
    width: 120,
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
    width: 120,
  },
] 

const cpColumns:ColumnsType<snListType> = [

  {
    title: "Order Code",
    dataIndex: "orderCode",
    key: "orderCode",
    width: 120,
  },
  {
    title: "Product Name",
    dataIndex: "friendlyName",
    key: "friendlyName",
    width: 120,
  },
  {
    title: "Product Code",
    dataIndex: "productCode",
    key: "productCode",
    width: 120,
  },
  {
    title: "Serial No",
    dataIndex: "snCode",
    key: "snCode",
    width: 120,
  },
  {
    title: "Redemption Code",
    dataIndex: "couponcode",
    key: "couponcode",
    width: 100,
  },
  {
    title: "Redeem Date",
    dataIndex: "redeemDate",
    key: "redeemDate",
    width: 120,
  },
] 


export default function RDHistory() {
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  type DataIndex = keyof DataType;
  const { Title } = Typography;


  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });


  const getData = async () =>{
    const res = await axios.get('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-coupon/all')
    setloading(false)
    const orders = sortByUpdatedDate(res.data)
  
    setdata(orders);
    console.log(orders)
  }

  function sortByUpdatedDate(data: any) {
    return data.sort((a:any, b:any) => {
        // Convert the updatedDate to a number using getTime()
        const dateA = new Date(a.updatedDate).getTime();
        const dateB = new Date(b.updatedDate).getTime();
        return dateB - dateA; // For descending order
    });
}


  const showModal = (record:any) => {
    setSelectedData(record);
    console.log(record)
    setIsModalVisible(true);
  };

  const tabonChange = (key: string) => {
    console.log(key);
  };


  useEffect(() => {
    getData() }
  , [])

  const requestSearch = (searchedVal:any) => {
    console.log(searchedVal)
    if (searchedVal === null || searchedVal === "") {
      getData() // Reset data to original when search is cleared
    } else {
      const filteredRows = data.filter((row) => {
        return Object.values(row).some(value =>
          String(value).toLowerCase().includes(searchedVal.toLowerCase())
        );
      });
      setdata(filteredRows);
    }
  };

  return (
      <>  
          <Row>
            <Col span={5}>
              <Title level={3}>Redemptions History</Title>
            </Col>
            <Col span={15}>
              <Input placeholder="Search" onChange={(e) => requestSearch(e.target.value)} style={{ width: 400, marginTop:"20px" }} />
            </Col>
            <Col span={4}>
              <Button style={{float: 'right', margin: '5px', marginTop: '20px'}} type="primary" shape="round" icon={<ReloadOutlined />} onClick={getData} size="large" />
            </Col>
          </Row>
          <Table 
            columns={cpColumns} 
            dataSource={data} 
            // scroll={{ y: 500 }} 
            loading={{ indicator: <div><Spin /></div>, spinning:loading}}
            // pagination={{ pageSize: 20 }}
            size="small"
            bordered
            showHeader
          />       	
 
      </>
  );

}


