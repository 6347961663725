import React, { useContext, useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import {
  Button, Form, Input, Popconfirm, Table, Flex, AutoComplete, Select, DatePicker, DatePickerProps, Spin,
  notification,

} from 'antd';
import type { NotificationArgsProps } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
// import { randomUUID } from 'crypto';
import { randomUUID } from 'crypto';

const inputId = "TEST-" + crypto.randomUUID().toString().slice(0, 8);
// const crypto = require('crypto');
// console.log(crypto.randomBytes(20).toString('hex'));
// import { options } from '../data/dataexports';

//
// table-multiple-editable-line-component
//
const EditableContext = React.createContext<FormInstance<any> | null>(null);
type NotificationPlacement = NotificationArgsProps['placement'];

interface Item {
  key: string;
  productCode: string;
  quantity: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {

  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [options, setOptions] = useState([])
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      // inputRef.current!.focus();
    }
  }, [editing]);

  useEffect(() => {

    const fetchData = async () => {
        try {
            const response = await axios.get('https://echo-assets.s3.ap-southeast-1.amazonaws.com/ac-final-mapping.json');
            setOptions(response.data);
            // console.log("data", response.data)

        } catch (error) {
            console.error('Error fetching data: ', error);
            // Handle errors here if needed
        }
    };

    fetchData();
}, []);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      // toggleEdit();
      handleSave({ ...record, ...values });

    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    save();
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === 'productCode' ? (
          <>
          <Select
            showSearch
            placeholder="Enter Product Code"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={options}
            />
          </>
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

//
// Form Component Declarations
//
const layout = {
  labelCol:
  {
    xs: { span: 3.5 },
    sm: { span: 4 },
   },
  wrapperCol: { span: 10 },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
const config = {
  rules: [{ type: 'object' as const, required: true, message: 'Please select Date!' }],
};

interface DataType {
  key: React.Key;
  productCode: string;
  quantity: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export default function JYSubmitTestNew() {
    const [dataSource, setDataSource] = useState<DataType[]>([
        {
          key: 0,
          productCode: 'eSIM',
          quantity: '2',
        }
      ]);

      const [count, setCount] = useState(1);
      const [loading, setLoading] = useState(false);
      const [seed, setSeed] = useState(1);
      const [api, contextHolder] = notification.useNotification();

      const reset = () => {
           setSeed(Math.random());
       }

       const successNotif = (placement: NotificationPlacement, message: any) => {
         api.info({
           message: `Notification ${placement}`,
           description:
             `Successful Submission. ${message}`,
           placement,
         });
         reset();
       };
      //
      // Table Helpers
      //

      const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
          title: 'Product Name',
          dataIndex: 'productCode',
          width: '40%',
          editable: true,
        },
        {
          title: 'Product Code',
          dataIndex: 'productName',
          render: (item,record) => (
            <Input value={record.productCode} disabled></Input>
          )
        },
        {
          title: 'Qty',
          dataIndex: 'quantity',
          editable: true,
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          render: (item,record) =>
          dataSource.length >= 1 ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
              <a>Delete</a>
            </Popconfirm>
          ) : null,
        },
      ];

      const handleAdd = () => {
        const newData: DataType = {
          key: count,
          productCode: `eSIM`,
          quantity: '1'
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
        console.log(...dataSource)
      };

      const handleSave = (row: DataType) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);

        console.log(item, row)
        console.log(newData)
      };

      const handleDelete = (key: string) => {
        setCount(count - 1)
        console.log(key)
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
        console.log(newData)
      };

      const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };

      const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: DataType) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      });

      //
      // Form Component Helpers
      //
      const [value, setValue] = React.useState(''); // State to hold the inputted value
      const [postData, setPostData] = useState({}) // State to hold the response data
      const [err, setErr] = useState(''); // State to hold error messages
      const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
      }; // Date picker onChange function

      const { Option } = Select; // Select component from antd
      const dateFormat = 'YYYY-MM-DD'; // Date format for the date picker
      const [form] = Form.useForm(); //form hook

      // Function to remove the "key" property from objects in an array
      function removeKeyFromArray(array:any) {
        return array.map((item:any) => {
          const { key, ...rest } = item;
          return rest;
        });
      }




      //
      // SIM Card API Declarations
      //

      // SIM Card API Params
      const [ag, setAg] = useState(""); // State to hold the autoGraph
      const [orderStage, setorderStage] = useState("") // State to hold the orderStage
      // const [orderCode, setOrderCode] = useState("") // State to hold the orderCode

      //SIM Card API Functions
      const customerCode = "20062";
      const customerAuth = "b80cB6c8F2"; // Keep it safe.
      const type = 3; // 这是一个必填项，Mandatory.
      const receiveName = "Jerry";
      const phone = "6581329924";
      const email = "jerry@echoyourtravels.com"; // 写你自己的邮箱，看看能不能收到邮件, replace with your e-mail
      const replyType = 1;
      let orderCode = "";
      let timestamp = Math.floor(Date.now());
      let orderTid = customerCode + timestamp + Math.random().toString(16).substr(2, 16);

      let str =
      customerCode +
      customerAuth +
      type +
      orderTid +
      receiveName +
      phone +
      timestamp;

      const itemList = [
        {
          key: 0,
          productCode: "eSIM-test",
          quantity: "1",
        },
      ];

      // const itemList: DataType[] = [
      //   ...dataSource
      // ];

      itemList.forEach((item) => {
        str += item.productCode + item.quantity;
      });

      async function sha1(input: any) {
        const msgBuffer = new TextEncoder().encode(input);
        const hashBuffer = await crypto.subtle.digest("SHA-1", msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("");
        // setag(hashHex)
        return hashHex;
      }

      const sendRequest = async (email:any) => {
        try {
          const autoGraph = await sha1(str);
          setAg(autoGraph);
          setorderStage(orderTid)
          const input = {
            customerCode,
            orderTid,
            timestamp,
            type,
            receiveName,
            phone,
            email,
            itemList,
            autoGraph,
            replyType
          };

          const response = await fetch("https://acrp.shukran.tech/customerApi/customerOrder", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(input),
          });

          console.log("AutoG", autoGraph);
          console.log("orderid", orderTid);
          console.log("MESSAGE", JSON.stringify(input));

          if (!response.ok) {
            console.log("ERROR MESSAGE", response);
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          if(data.code === 0)
          {
            orderCode = data.data.orderCode
            orderTid = data.data.orderTid
            console.log("orderCode", data.data.orderCode)
            console.log("orderTidNew", data.data.orderTid)
          }
          else
          {
            alert("Error: Something went wrong " + data.message)
          }
          console.log("Success:", data);
        } catch (error) {
          console.error("Error:", error);
        }
      };

      //
      // Form Component Functions
      //
      const onFinish = async (values: any) => {
        setLoading(true)
        await sendRequest(values.order.oEmail);
        // Combine orderInfo and eSIMCardsWithoutKeys
        values.order.oNumber = inputId
        console.log(values.order)
        const combinedData = {
          order: values.order,
          orderProcessedDate: dayjs(values.orderProcessedDate).format('YYYY-MM-DD'),
          eSIMCards: removeKeyFromArray(dataSource),
          orderCode: orderCode,
          orderTid: orderTid,
          empName: "sjace",
        };
        console.log("combine" + combinedData.eSIMCards);
        console.log(dayjs(values.orderProcessedDate).format('YYYY-MM-DD'));
        await PostData(combinedData);

      };

      const PostData = async (values:any) => {
          try {
            const response = await fetch('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/test/ac-order-fulfillment', {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              },
            });

            if (!response.ok) {
              throw new Error('Network response was not ok');
            }

            console.log(JSON.stringify(values));
            const data = await response.json();
            setPostData(data); // Set the response data in state
            setLoading(false)
            setErr(''); // Clear any previous errors
            console.log(data)
            alert("Order Submitted Successfully")
            window.location.reload();
            reset();
          } catch (err) {
            setErr((err as Error).message); // Set error message in state
          }
        };

      useEffect(() => {
        console.log(inputId)
        form.setFieldsValue({
          orderProcessedDate: dayjs()
        });
      }, [form, orderCode]);

  return (
    <>
        { loading ?
            <div className="fullscreen-spinner">
              <Spin size="large" />
            </div> : null
        }
        <h1>Test Order</h1>
        <Form
            key={seed}
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            form={form}
          >
            {/* <Form.Item name={['order', 'oNumber']} label="Online Order Number" rules={[{ required: true }]}>
              <Input />
            </Form.Item> */}
            <Form.Item name={['orderProcessedDate']} label="Test Processed Date" rules={[{ required: true }]} getValueProps={(i) => ({value: dayjs(i)})}>
              <DatePicker format={dateFormat}/>
            </Form.Item>
            <Form.Item name={['order', 'oEmail']} label="Test Email" rules={[{ type: 'email', required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
            name={['order', 'oPlatform']}
            label="Platform"
            hasFeedback
            rules={[{ required: true, message: 'Please select a platform!' }]}
          >
            <Select placeholder="Please select a platform">
              <Option value="lazada">Lazada</Option>
              <Option value="shopee">Shopee</Option>
              <Option value="shopify">Shopify</Option>
              <Option value="amazon">Amazon</Option>
            </Select>
          </Form.Item>

            <Form.Item name={['order', 'oComments']} label="Comments">
              <Input.TextArea />
            </Form.Item>
             <div style={{ textAlign: 'right' }}>
              <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                  Add a Product
              </Button>
              <Table
                  style={{ marginBottom: 20}}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns as ColumnTypes}
                  pagination={false}
              />
            </div>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
              <Button type="primary" htmlType="submit" icon={<RightOutlined />}>
                Submit Order
              </Button>
            </Form.Item>
        </Form>
        <br />
        <br />

    </>
  );
};
