import {
  Button,
  Col,
  Flex,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Table,
  Tabs,
  Typography,
} from "antd";
import { SimCard } from ".";
import Container from "../Container";
import { formatDateTimeISO } from "../../../utils/date";
import { useEffect, useState } from "react";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import { validateEmail } from "../../../utils/emailValidation";

const { Text } = Typography;

const RESEND_EMAIL_API_PROD_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/sb-data-mapping/resend-email";
const RESEND_EMAIL_API_DEV_URL = "https://wv90e9zk2m.execute-api.ap-southeast-1.amazonaws.com/test/sb-data-mapping/resend-email";

const RESEND_EMAIL_API_URL = process.env.NODE_ENV === "production" || process.env.VERCEL_ENV === "production" ? RESEND_EMAIL_API_PROD_URL : RESEND_EMAIL_API_DEV_URL;

interface InventoryModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  sim: SimCard | null; // to be updated
}

export default function InventoryModal(props: InventoryModalProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const [emailToResend, setEmailToResend] = useState<string>("");

  useEffect(() => {
    if (props.sim !== null) {
      setEmailToResend(props.sim.customerEmail);
    } else {
      setEmailToResend("");
    }
  }, [props.sim]);

  function handleOk() {
    // TODO: Save
    props.setIsModalVisible(false);
  }

  function handleCancel() {
    props.setIsModalVisible(false);
  }

  async function handleResendEmail() {
    if (emailToResend === "") {
      messageApi.error("Email address cannot be empty.");
      return;
    }

    if (!validateEmail(emailToResend)) {
      messageApi.error("Invalid email address.");
      return;
    }

    messageApi.open({
      type: "loading",
      content: "Resending email...",
      duration: 0,
    });
    try {
      await axios.post(RESEND_EMAIL_API_URL, {
        snNo: props.sim!!.snNo,
        email: emailToResend,
      });
      messageApi.destroy();
      messageApi.success("Email sent!");
    } catch (ex) {
      messageApi.destroy();
      messageApi.error("Failed to resend email.");
    }
  }

  return (<>
    {contextHolder}
    <Modal
      className="order-modal"
      width="80%"
      title="eSIM Details"
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.sim === null || props.sim === undefined ? (
        <Spin />
      ) : (
        <Tabs
          type="card"
          defaultActiveKey="1"
          items={[
            {
              label: "Order Detail",
              key: "1",
              children: (
                <Container>
                  <Text type="secondary">Order Information</Text>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Input
                        readOnly
                        addonBefore="Customer Email"
                        value={props.sim.customerEmail}
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        readOnly
                        addonBefore="Order Processed Date"
                        value={props.sim.orderProcessedDate}
                      />
                    </Col>
                    <Col span={8}>
                      <Input
                        readOnly
                        addonBefore="System Status"
                        value={props.sim.status === ""
                              ? "Available"
                              // temporary workaround until db is updated
                              : props.sim.status === "FULFILLED"
                              ? "Used"
                              : props.sim.status}
                      />
                    </Col>
                  </Row>

                  <Text type="secondary">SIM Card</Text>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Imported On"
                        value={formatDateTimeISO(props.sim.dateTime)}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        readOnly
                        addonBefore="Expiry Date"
                        value={props.sim.expiryDate}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Input addonBefore="Item Name" value={props.sim.name} />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={24}>
                      <Input
                        addonBefore="Activation Code URL"
                        value={props.sim.activationCodeUri}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Input
                        addonBefore="SM-DP+ Address"
                        value={props.sim.smDpAddress}
                      />
                    </Col>
                    <Col span={12}>
                      <Input
                        addonBefore="Confirmation Code"
                        value={props.sim.confirmationCode}
                      />
                    </Col>
                  </Row>
                </Container>
              ),
            },
            {
              label: "Resend Email",
              key: "2",
              disabled: props.sim.status === "",
              children: (
                <Container>
                  <Row justify="space-between" gutter={8}>
                    <Col flex="auto">
                      <Input
                        addonBefore="Email Address"
                        type="email"
                        value={emailToResend}
                        onChange={(e) => setEmailToResend(e.target.value.trim())}
                      />
                    </Col>
                    <Col>
                      <Button
                        onClick={handleResendEmail}
                        icon={<MailOutlined />}
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Container>
              ),
            },
            {
              label: "Comments",
              key: "3",
              disabled: props.sim.status === "",
              children: (
                <>
                  <Input.TextArea
                    value={props.sim.comments}
                    readOnly
                    autoSize={{ minRows: 3, maxRows: 5 }}
                  />
                </>
              ),
            },
          ]}
        />
      )}
    </Modal>
  </>);
}
