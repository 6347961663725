import {
    Breadcrumb,
    Layout,
    Menu,
    theme,
    Button,
    Space,
    Checkbox,
    Form,
    Input,
  } from "antd";
  import echoWhite from '../components/assets/logo-white.png';
import { MenuButton } from "@aws-amplify/ui-react";
import { Auth } from 'aws-amplify';


export default function NavBar() {

    async function signOut() {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <>
        <div className="logo" >
            <img height="50px" width="130px" src={echoWhite} alt="Echo" />
        </div>
        <div>
            <Button type="primary" onClick={signOut}>
                Logout
            </Button>
        </div>
        </>
    )

}