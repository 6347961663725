import React, { useState, useEffect } from 'react';
import { Table, Input, Button, Spin, Row, Col, Typography, Tag } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';

interface RechargeList {
  key: React.Key;
  phoneNumber: string;
  network: string;
  rechargeAmount: string;
  rechargeType: string;
  employeeName: string;
  createdDate: string; // Add the createdDate property
}

// Define the columns with type ColumnsType<RechargeList>
const cpColumns: ColumnsType<RechargeList> = [
  {
    title: 'Recharge Date', // New column for createdDate
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: 120,
    render: (_: any, record: RechargeList) => dayjs(record.createdDate).format('DD MMM YYYY HH:mm'), // Format the date as 24 Oct 2024 09:10
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: 120,
    responsive: ['sm'], // Only show on screens larger than 'sm'
  },
  {
    title: 'Network',
    dataIndex: 'network',
    key: 'network',
    width: 120,
    responsive: ['sm'],
  },
  {
    title: 'Recharge Amount',
    dataIndex: 'rechargeAmount',
    key: 'rechargeAmount',
    width: 180,
    render: (_: any, record: RechargeList) => (
      <span>
        {record.rechargeAmount}
        <Tag color={record.rechargeType === 'top up' ? 'blue' : 'green'} style={{ marginLeft: 8 }}>
          {record.rechargeType === 'top up' ? 'Top Up' : 'Bundle'}
        </Tag>
      </span>
    ),
  },
  {
    title: 'Processed By',
    dataIndex: 'employeeName',
    key: 'employeeName',
    width: 150,
    responsive: ['sm'],
  },

];

export default function RechargeHistory() {
  const [data, setData] = useState<RechargeList[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { Title } = Typography;

  const getData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        'https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/em-order-fulfillment/recharge/all'
      );
      const transformedData = res.data.map((item: any, index: number) => ({
        key: index,
        phoneNumber: item.phoneNumber,
        network: item.network,
        rechargeAmount: item.bundleValue ? item.bundleValue : item.topUpValue,
        rechargeType: item.bundleValue ? 'bundle' : 'top up',
        employeeName: item.employeeName,
        createdDate: item.createdDate, // Include the createdDate in the data transformation
      }));
      setData(transformedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(); // Fetch the data when the component mounts
  }, []);

  // Function to filter data globally based on search text
  const filteredData = data.filter((item) =>
    Object.keys(item).some((key) =>
      String(item[key as keyof RechargeList]).toLowerCase().includes(searchText.toLowerCase())
    )
  );

  return (
    <>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={24} md={5}>
          <Title level={3} style={{ textAlign: 'center' }}>Recharge History</Title>
        </Col>
        <Col xs={24} sm={18} md={15}>
          <Input
            placeholder="Search entire table"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: '100%', marginTop: '20px' }}
          />
        </Col>
        <Col xs={24} sm={6} md={4}>
          <Button
            style={{ width: '100%', marginTop: '20px' }}
            type="primary"
            shape="round"
            icon={<ReloadOutlined />}
            onClick={getData}
            size="large"
          >
            Reload
          </Button>
        </Col>
      </Row>
      <Table
        columns={cpColumns} // Ensure cpColumns is correctly typed
        dataSource={filteredData}
        loading={{ indicator: <div><Spin /></div>, spinning: loading }}
        size="small"
        bordered
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }} // Allow horizontal scrolling on small screens
        style={{ marginTop: 16 }}
      />
    </>
  );
}