import React, { useContext, useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import { Button, Form, Input, Popconfirm, Table, Select, DatePicker, DatePickerProps, Spin, message } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { RightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
// import { options } from '../data/dataexports';
import { Auth } from 'aws-amplify';
import mappings from './test-ac-full-final-mapping.json'

const TEST_ENDPOINT_URL = "https://wv90e9zk2m.execute-api.ap-southeast-1.amazonaws.com/test/simulation/provider/joytel/order";
const PROD_ENDPOINT_URL = "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/order-management/portal-admin/order";

//
// table-multiple-editable-line-component
//
const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  productCode: string;
  quantity: string;
}

interface NewItem {
  model_sku: string;
  model_quantity_purchased: string;
}

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {

  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const options = mappings.map(mapping => ({
    label: mapping.label,
    value: mapping.label  // Workaround for idential code with multiple labels
  }));
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      // inputRef.current!.focus();
    }
  }, [editing]);

//   useEffect(() => {
//     const fetchData = async () => {
//         try {
//             const response = await axios.get(mappings);
//             setOptions(response.data);
//             // console.log("data", response.data)
//         } catch (error) {
//             console.error('Error fetching data: ', error);
//             // Handle errors here if needed
//         }
//     };
//     fetchData();
// }, []);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      // toggleEdit();
      const mappingCode = mappings.find(x => x.label === values.productCode)!!.label;
      handleSave({ ...record, ...values, productCode: mappingCode });

    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    save();
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {dataIndex === 'productCode' ? (
          <>
          <Select
            showSearch
            placeholder="Enter Product Code"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={options}
            />
          </>
        ) : (
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        )}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

//
// Form Component Declarations
//
const layout = {
  labelCol:
  {
    xs: { span: 3.5 },
    sm: { span: 4 },
   },
  wrapperCol: { span: 10 },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
const config = {
  rules: [{ type: 'object' as const, required: true, message: 'Please select Date!' }],
};

interface DataType {
  key: React.Key;
  productCode: string;
  quantity: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export default function JYSubmit() {
    const [messageApi, contextHolder] = message.useMessage();
    const [dataSource, setDataSource] = useState<DataType[]>([
        {
          key: 0,
          productCode: 'eSIM',
          quantity: '1',
        }
      ]);

      const [count, setCount] = useState(1);
      const [loading, setLoading] = useState(false);

      //
      // Table Helpers
      //

      const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
        {
          title: 'Product Name',
          dataIndex: 'productCode',
          width: '40%',
          editable: true,
        },
        {
          title: 'Product Code',
          dataIndex: 'productName',
          render: (item,record) => {
            const jtCode = mappings.find(x => x.label === record.productCode)?.code;
            return (
              <Input value={jtCode} disabled></Input>
            )
          }
        },
        {
          title: 'Qty',
          dataIndex: 'quantity',
          editable: true,
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          render: (item,record) =>
          dataSource.length >= 1 ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
              <a>Delete</a>
            </Popconfirm>
          ) : null,
        },
      ];

      const handleAdd = () => {
        const newData: DataType = {
          key: count,
          productCode: `eSIM`,
          quantity: '1'
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
        console.log(...dataSource)
      };

      const handleSave = (row: DataType) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);

        console.log(item, row)
        console.log(newData)
      };

      const handleDelete = (key: string) => {
        setCount(count - 1)
        console.log(key)
        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
        console.log(newData)
      };

      const components = {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      };

      const columns = defaultColumns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          onCell: (record: DataType) => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      });

      //
      // Form Component Helpers
      //
      const [value, setValue] = React.useState(''); // State to hold the inputted value
      const [postData, setPostData] = useState({}) // State to hold the response data
      const [err, setErr] = useState(''); // State to hold error messages
      const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
      }; // Date picker onChange function

      const { Option } = Select; // Select component from antd
      const dateFormat = 'YYYY-MM-DD'; // Date format for the date picker
      const [form] = Form.useForm(); //form hook

      // Function to remove the "key" property from objects in an array
      function removeKeyFromArray(array:any) {
        return array.map((item:any) => {
          const { key, ...rest } = item;
          return rest;
        });
      }

      //
      // SIM Card API Declarations
      //

      // SIM Card API Params
      const [ag, setAg] = useState(""); // State to hold the autoGraph
      const [orderStage, setorderStage] = useState("") // State to hold the orderStage
      const [uname, setuname] = useState("")
      // const [orderCode, setOrderCode] = useState("") // State to hold the orderCode

      //SIM Card API Functions
      const customerCode = "20062";
      const customerAuth = "b80cB6c8F2"; // Keep it safe.
      const type = 3; // 这是一个必填项，Mandatory.
      const receiveName = "Jerry";
      const phone = "6588899025";
      const email = "jerry@echoyourtravels.com"; // 写你自己的邮箱，看看能不能收到邮件, replace with your e-mail
      const replyType = 1;
      let orderCode = "";
      let timestamp = Math.floor(Date.now());
      let orderTid = customerCode + timestamp + Math.random().toString(16).substr(2, 16);

      let str =
      customerCode +
      customerAuth +
      type +
      orderTid +
      receiveName +
      phone +
      timestamp;

      // const itemList = [
      //   {
      //     key: 0,
      //     productCode: "eSIM-test",
      //     quantity: "3",
      //   },
      // ];

      const itemList: DataType[] = [
        ...dataSource
      ];

      itemList.forEach((item) => {
        str += item.productCode + item.quantity;
      });

      async function sha1(input: any) {
        const msgBuffer = new TextEncoder().encode(input);
        const hashBuffer = await crypto.subtle.digest("SHA-1", msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((b) => b.toString(16).padStart(2, "0"))
          .join("");
        // setag(hashHex)
        return hashHex;
      }

      //
      // Form Component Functions
      //
      const onFinish = async (values: any) => {
        setLoading(true);

        let items = removeKeyFromArray(dataSource);

        // Update attributes
        items = items.map((item: Item) => ({
          model_sku: item.productCode,
          model_quantity_purchased: item.quantity,
        }))

        // Split into test and prod items
        const testItems = items.filter((item: NewItem) => item.model_sku.includes('eSIM-test'));
        const prodItems = items.filter((item: NewItem) => !item.model_sku.includes('eSIM-test'));

        let p1: Promise<void> | null = null;
        let p2: Promise<void> | null = null;
        if (testItems.length > 0) {
          const orderDetails = {
            order_sn: values.order.oNumber,
            processStaff: uname,
            OrderDetails: {
              Payload: {
                message_to_seller: values.order.oEmail,
                item_list: testItems,
              },
            },
          };
          console.log("order details v2 test", orderDetails);
          p1 = PostData(orderDetails, true);
        }

        if (prodItems.length > 0) {
          const orderDetails = {
            order_sn: values.order.oNumber,
            processStaff: uname,
            OrderDetails: {
              Payload: {
                message_to_seller: values.order.oEmail,
                item_list: prodItems,
              },
            },
          };
          console.log("order details v2 prod", orderDetails);
          p2 = PostData(orderDetails, false);
        }

        const promises = [p1, p2].filter((p) => p !== null) as Promise<void>[];
        await Promise.all(promises);
        setLoading(false);

        // Format order details for backend API

        // await PostData(orderDetails);
      };

      // send request to backend API to trigger workflow for processing order
      const PostData = async (values: any, isTest: boolean) => {
        try {
          const response = await fetch(
            isTest ? TEST_ENDPOINT_URL : PROD_ENDPOINT_URL,
            {
              method: "POST",
              body: JSON.stringify(values),
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          console.log(JSON.stringify(values));
          const data = await response.json();
          setPostData(data); // Set the response data in state
          setErr(""); // Clear any previous errors
          console.log(data);
          alert("Order Submitted Successfully");
          window.location.reload();
        } catch (err) {
          setErr((err as Error).message); // Set error message in state
          messageApi.error("Error: Something went wrong");
        }
      };

      useEffect(() => {
        form.setFieldsValue({
          orderProcessedDate: dayjs()
        });
        const fetchUser = async () => {
          let session = await Auth.currentUserInfo();
          console.log('User info Session', session.username)
          setuname(session.username)
        }
        fetchUser();
      }, [form, orderCode, uname]);

      const emailDomainCorrections = {
        'gamil.com': 'gmail.com',
        'gnail.com': 'gmail.com',
        'gmal.com': 'gmail.com',
        'gmial.com': 'gmail.com',
        'gmaill.com': 'gmail.com',
        'gmil.com': 'gmail.com',
        'hotnail.com': 'hotmail.com',
        'hotmai.com': 'hotmail.com',
        'hotmial.com': 'hotmail.com',
        'hotmial.co': 'hotmail.com',
        'hotmal.com': 'hotmail.com',
        'yaho.com': 'yahoo.com',
        'yahho.com': 'yahoo.com',
        'yhoo.com': 'yahoo.com',
        'yahoom.com': 'yahoo.com',
        'yahool.com': 'yahoo.com',
        'outllok.com': 'outlook.com',
        'outlok.com': 'outlook.com',
        'outlook.co': 'outlook.com',
        'outlok.co': 'outlook.com',
        'icloud.co': 'icloud.com',
        'icoud.com': 'icloud.com',
        'icluod.com': 'icloud.com',
        'iclod.com': 'icloud.com',
      };

      const validateEmailDomain = (rule:any, value:any) => {
        if (value) {
          const domain = value.split('@')[1] as keyof typeof emailDomainCorrections;
          if (domain && emailDomainCorrections[domain]) {
            return Promise.reject(`Did you mean ${emailDomainCorrections[domain]}?`);
          }
        }
        return Promise.resolve();
      };

  return (
    <>
        {contextHolder}
        { loading ?
            <div className="fullscreen-spinner">
              <Spin size="large" />
            </div> : null
        }

        <Form
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            validateMessages={validateMessages}
            form={form}
          >
            <Form.Item name={['order', 'oNumber']} label="Online Order Number" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={['orderProcessedDate']} label="Order Processed Date" rules={[{ required: true }]} getValueProps={(i) => ({value: dayjs(i)})}>
              <DatePicker format={dateFormat}/>
            </Form.Item>
            <Form.Item name={['order', 'oEmail']} label="Customer Email" rules={[{ type: 'email', required: true, },{ validator: validateEmailDomain }]}>
              <Input />
            </Form.Item>
            {/* <Form.Item
            name={['order', 'oPlatform']}
            label="Platform"
            hasFeedback
            rules={[{ required: true, message: 'Please select a platform!' }]}
          >
            <Select placeholder="Please select a platform">
              <Option value="lazada">Lazada</Option>
              <Option value="shopee">Shopee</Option>
              <Option value="shopify">Shopify</Option>
              <Option value="amazon">Amazon</Option>
            </Select>
          </Form.Item> */}

            <Form.Item name={['order', 'oComments']} label="Comments">
              <Input.TextArea />
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
              <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                  Add a Product
              </Button>
              <Table
                  style={{ marginBottom: 20}}
                  components={components}
                  rowClassName={() => 'editable-row'}
                  bordered
                  dataSource={dataSource}
                  columns={columns as ColumnTypes}
                  pagination={false}
              />
            </div>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
              <Button type="primary" htmlType="submit" icon={<RightOutlined />}>
                Submit Order
              </Button>
            </Form.Item>
        </Form>
        <br />
        <br />

    </>
  );
};
