import React, { useContext, useEffect, useRef, useState } from 'react';
import { Table, Space, Input, Dropdown, Button, message, Spin, Modal, Tabs, Row, Col, Typography, Tag } from 'antd';
import type { InputRef } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, UserOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import axios from 'axios'
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'


export default function UMapping() {
    let objectid_store = ""
    let uploadurl_link = ""

    const [err, setErr] = useState('');
    const [fileState, setFileState] = useState('')
    const [data, setData] = useState([]);
    const [loading, setloading] = useState(true)
    const { Title } = Typography;

    useEffect(() => {
        fetchData();
    },[fileState]);   

    // Columns configuration
    const columns = [
        {
            title: 'Job Name',
            dataIndex: 'Job Name',
            key: 'jobName',
        },
        {
            title: 'Run Status',
            dataIndex: 'Run Status',
            key: 'runStatus',
            render: (status: string) => { // Explicitly type the 'status' parameter as string
                let color = 'volcano';  // Default to red for failed jobs
                if (status === 'SUCCEEDED') color = 'green';
                else if (status === 'RUNNING') color = 'blue';

                return <Tag color={color}>{status}</Tag>;
            }
        },
        {
            title: 'Start Time',
            dataIndex: 'Start Time',
            key: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'End Time',
            key: 'endTime',
        }
    ];

    const handleChangeStatus = ({ meta, file }:any,  status:any) => {
        console.log(status, meta, file)
        setFileState(status)
    }

    const handleSubmitTR = (files: any) => {
        console.log(files.map((f:any) => f.meta));
      };
    
    const handleSubmit = async (files:any, allFiles:any) => {
        console.log(files.map((f: any) => f.meta))
        const f = files[0];
        try {
          const response = await fetch('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-data-mapping/uploadfile', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          });
    
          if (!response.ok) {
            throw new Error(`Error! status: ${response.status}`);
            
          }
    
          const result = await response.json();
          console.log('result is: ', JSON.stringify(result, null, 4));
          uploadurl_link = result.uploadUrl
          objectid_store = result.objectId
          console.log(result.uploadUrl)
          const submitFile = await fetch(uploadurl_link, {
            method: "PUT",
            body: f["file"],
          });
          setFileState('uploaded')
          allFiles.forEach((f:any) => f.remove())
          fetchData()
          // PUT request: upload file to S3
          // sendtoDB()
          // alert("qr code succesfully uploaded!");
    
        } catch (err) {
          setErr((err as Error).message);
        } finally {
    
        }
      }

      const fetchData = async () => {
        const response = await fetch('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-data-mapping/getstatus');
        const result = await response.json();
        setData(result); // Assuming the API returns the array directly
        setloading(false)
    };

      const refreshData = () => {
        console.log("Refreshing data...");
        fetchData(); // Re-fetch the data when the button is clicked
     };


    return (
        <>  
            <Row>
              <Col span={8}>
                <Title level={3}>Update Mapping</Title>
              </Col>
            </Row>
            <Dropzone
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                maxFiles={1}
                multiple={false}
                canCancel={true}
                inputContent="Drag file here or browse"
                styles={{
                    dropzone: { width: 800, height: 200 },
                    dropzoneActive: { borderColor: "black" },
                }}
            />
            <div style={{ marginBottom: 5, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                icon={<ReloadOutlined />}
                onClick={refreshData}
                style={{ marginBottom: 5 }}
            >
                Refresh
            </Button>
           
            </div>
            <Table 
                dataSource={data} 
                columns={columns} 
                loading={{ indicator: <div><Spin /></div>, spinning:loading}}
                size="small" />
        </>
    );

}