import React, { useState, useRef } from "react";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Breadcrumb,
  Layout,
  Menu,
  theme,
  Button,
  Space,
  Checkbox,
  Form,
  Input,
  Row,
  Col,
  Card,
  Spin
} from "antd";

export default function ManualCallback() {

  // const [replyType, setReplyType] = useState(0); // 0 for email, 1 for callback
  const [ag, setAg] = useState("");
  const [orderStage, setorderStage] = useState("")
  const [orderCode, setOrderCode] = useState("")
  const [jsonRes, setjsonRes] = useState("")
  const [manualRes, setManualRes] = useState("")
  const [displayPtr, setDisplayPtr] = useState("none")
  const [manualResSend, setManualResSend] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const orderid = useRef(null);
  
  const testCall = {
    "orderTid": "200621702918134804b29fbe859612b",
    "totalAmount": 0,
    "receiveName": "Jerry",
    "phone": "6581329924",
    "outboundCode": "OB-231219-799245",
    "orderCode": "DD-231219-873085",
    "itemList": [
      {
        "productCode": "eSIM-test",
        "quantity": 2,
        "snList": [
          {
            "snCode": "89862100094500146291",
            "productExpireDate": "2024-12-31",
            "snPin": "SRzmM7c50x"
          },
          {
            "snCode": "89862100094500146290",
            "productExpireDate": "2024-12-31",
            "snPin": "L6Wc6g0UhB"
          }
        ],
        "productName": "eSIM-test"
      }
    ],
    "email": "echo@shukran.tech",
    "status": 4
  }

  //
  // SIM CARD API
  //
  const customerCode = "20062";
  const customerAuth = "b80cB6c8F2"; // Keep it safe.
  const type = 3; // 这是一个必填项，Mandatory.
  const receiveName = "Jerry";
  const phone = "6581329924";
  const email = "jerry@echoyourtravels.com"; // 写你自己的邮箱，看看能不能收到邮件, replace with your e-mail
  const replyType = 1;
  let timestamp = Math.floor(Date.now());
  let orderTid = customerCode + timestamp + Math.random().toString(16).substr(2, 16);

  let str =
    customerCode +
    customerAuth +
    type +
    orderTid +
    receiveName +
    phone +
    timestamp;

  const itemList = [
    {
      productCode: "eSIM-test",
      quantity: "2",
    },
  ];

  itemList.forEach((item) => {
    str += item.productCode + item.quantity;
  });

  async function sha1(input: any) {
    const msgBuffer = new TextEncoder().encode(input);
    const hashBuffer = await crypto.subtle.digest("SHA-1", msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    // setag(hashHex)
    return hashHex;
  }

  const sendRequest = async () => {
    try {
      const autoGraph = await sha1(str);
      setAg(autoGraph);
      setorderStage(orderTid)
      const input = {
        customerCode,
        orderTid,
        timestamp,
        type,
        receiveName,
        phone,
        email,
        itemList,
        autoGraph,
        replyType
      };

      const response = await fetch("https://acrp.shukran.tech/customerApi/customerOrder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(input),
      });

      console.log("AutoG", autoGraph);
      console.log("orderid", orderTid);
      console.log("MESSAGE", JSON.stringify(input));

      if (!response.ok) {
        console.log("ERROR MESSAGE", response);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if(data.code === 0)
      {
        setOrderCode(data.data.orderCode)
        orderTid = data.data.orderTid
        console.log("orderCode", data.data.orderCode)
        console.log("orderTidNew", data.data.orderTid)
      }
      console.log("Success:", data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // if (replyType === 1) {
  //   input.replyType = 1;
  // }

  const manualCb = async () => {
    console.log("submitted", orderCode)
    setIsLoading(true)
    try {
        const response = await fetch("https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/manual/order-cb", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(manualResSend),
      });

      // console.log('autoGrapth', testAg)
      console.log("MESSAGE", manualRes);
      

      if (!response.ok) {
        console.log("ERROR MESSAGE", response);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
      alert("Manual Callback Sent")
      setIsLoading(false)
      window.location.reload()

    } catch (error) {
        console.error("Error:", error);
    }
  }

  const sendorderq = async () => {
    try {

      orderTid = orderStage
      timestamp = Math.floor(Date.now())
      // setOrderCode(orderCode.replace(/\s/g, ""))

      let strspc =
        customerCode +
        customerAuth +
        orderCode +
        timestamp;

      console.log("strspc", strspc)
      const autoGraph = await sha1(strspc)

      const ordq = {
        customerCode,
        timestamp,
        autoGraph,
        orderCode
      };

      const response = await fetch("https://acrp.shukran.tech/customerApi/customerOrder/query", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ordq),
      });

      // console.log('autoGrapth', testAg)
      console.log("MESSAGE", JSON.stringify(ordq));
      

      if (!response.ok) {
        console.log("ERROR MESSAGE", response);
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Success:", data);
      console.log('manualCall', data.data)
      setjsonRes(JSON.stringify(data , null, 2))
      setManualRes(JSON.stringify(data.data , null, 2))
      setDisplayPtr("")
      setManualResSend(data.data)
    } catch (error) {
      console.error("Error:", error);
    }
  };

    return (
      <>
        <Row>
          <Row></Row>
          <Col span={8}>
            <Input addonBefore="Order Code" placeholder="input search text" onChange={(e) => setOrderCode((e.target.value).replace(/\s/g, ""))} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>   
          <div style={{textAlign:"right"}}>
            <Button type="primary" onClick={sendorderq} style={{marginTop: "20px", marginBottom: "20px"}}>
                Query Order
              </Button>   
              <Card style={{ width: 500, marginBottom: "20px", marginTop: "20px", textAlign:"left" }}>
                <h3>Vanilla Response</h3>
                <pre>{jsonRes}</pre>
          </Card>
          </div>
          </Col>
          <Col span={8}>
            <Spin spinning={isLoading} >
                <Card style={{ width: 500, marginBottom: "20px", marginTop: "90px", textAlign:"left", marginLeft:"60px" }}>
                    <h3>Manual Response To Send</h3>
                    <pre>{manualRes}</pre>
                    {/* {JSON.stringify(manualResSend)} */}
                    <Button type="primary" color="red" onClick={manualCb} style={{marginTop: "20px", marginBottom: "20px", display:displayPtr}} >
                        Send Manual Callback
                    </Button>   
                </Card>
            </Spin>
          </Col>
        </Row>
        <Row>

        </Row>

        {/* <Button type="primary" onClick={sendRequest}>
            Purchase ESIM
          </Button> */}
      </>
    )

}