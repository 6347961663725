import React, { useContext, useEffect, useRef, useState } from 'react';
import { 
  Table, Space, Input, Dropdown, Button, message, Spin, Modal, Tabs, Row, Col, Typography,

 } from 'antd';
import type { InputRef } from 'antd';
import type { MenuProps } from 'antd';
import { DownOutlined, UserOutlined, SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import type { ColumnsType, ColumnType } from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import axios from 'axios'
import { tab } from '@testing-library/user-event/dist/tab';
const { Search } = Input;

interface EsimDataType {
  label: string;
  value: string;
};
interface DataType {
  key: React.Key;
  createdAt: string;
  oNumber: string;
  itemList: string;
  orderStatus: string;
  customerEmail: string;
  orderCode: string;
  orderProcessedDate: string;
}

interface snListType {
  key: React.Key;
  productName: string;
  productCode: string;
  snCode: string;
  productExpireDate: string;
  snPin: string;
}

interface itemListType {
  key: React.Key;
  productCode: string;
  quantity: string;
}

export default function JYHistoryTest() {
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [options, setOptions] = useState<EsimDataType[]>([])
  const [err, setErr] = useState(''); // State to hold error messages
  type DataIndex = keyof DataType;
  const { Title } = Typography;
  const emailRef = useRef<InputRef>(null);
  const [orderNumber, setOrderNumber] = useState('');
  const [email, setEmail] = useState('');
  const [joytelOrderNo, setJoytelOrderNo] = useState('');
  
  const getLabelByValue = (value: string): string | undefined => {
    const item = options.find(data => data.value === value);
    return item ? item.label : undefined;
  };

  const shopeeColumns = [
    {
      title: 'Shop ID',
      dataIndex: 'ShopId',
      key: 'ShopId',
    },
    {
      title: 'Order ID',
      dataIndex: 'OrderId',
      key: 'OrderId',
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
    },
    {
      title: 'Product Type',
      dataIndex: 'ProductType',
      key: 'ProductType',
    },
  ];
  

  const itemColumns:ColumnsType<itemListType> = [
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
      width: 120,
    },
    {
      title: "Product Label",
      dataIndex: "productCode",
      key: "productLabel",
      width: 200,
      render: (text: string) => getLabelByValue(text) || text,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 120,
    },
  ] 

  const snColumns:ColumnsType<snListType> = [
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 120,
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
      width: 120,
    },
    {
      title: "Product Label",
      dataIndex: "productCode",
      key: "productLabel",
      width: 200,
      render: (text: string) => getLabelByValue(text) || text,
    },
    {
      title: "SN Code",
      dataIndex: "snCode",
      key: "snCode",
      width: 120,
    },
    {
      title: "SN Pin",
      dataIndex: "snPin",
      key: "snPin",
      width: 120,
    },
    {
      title: "Product Expire Date",
      dataIndex: "productExpireDate",
      key: "productExpireDate",
      width: 120,
    },
  ] 

  const columns: ColumnsType<DataType> = [
    {
      title: "Order Date",
      dataIndex: "orderProcessedDate",
      key: "orderProcessedDate",
      width: 90,
      // defaultSortOrder: 'descend',
      // sorter: (a, b) => {
      //   const dateA = new Date(a.orderProcessedDate.split('/').reverse().join('-'));
      //   const dateB = new Date(b.orderProcessedDate.split('/').reverse().join('-'));
      //   return dateA.getTime() - dateB.getTime();
      // },
    },
    {
      title: "Online Order Number",
      dataIndex: "oNumber",
      key: "oNumber",
      width: 100,
      // ...getColumnSearchProps('oNumber'),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      width: 120,
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      width: 100,
      // ...getColumnSearchProps('customerEmail'),
    },
    {
      title: "Processed By",
      width: 100,
      dataIndex: "employeeName",
      key: "employeeName",
      // ...getColumnSearchProps('orderCode'),
    },
    { title: "Action", 
      key: "operation", 
      width: 100,
      render: (text, record) => <a onClick={() => showModal(record)}>View</a>
    },
  ];

  const getData = async () => {
    try {
      const res = await axios.get('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-order-fulfillment');
      setloading(false);
      const orders = res.data;
  
      const sorted = orders.sort((a: any, b: any) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });
  
      const mergedData = sorted.map((order: any) => {
        // Check if order.snList is defined and is an array, otherwise default to an empty array
        const processedSnList = (order.snList || []).map((item: any) => {
          // Ensure item.snList is an array
          const itemSnList = (item.snList || []).map((sn: any) => ({
            ...sn,
            productCode: item.productCode,
            productName: item.productName,
          }));
          return {
            ...item,
            snList: itemSnList,
          };
        });
  
        return {
          ...order,
          createdAt: new Date(order.createdAt).toLocaleString(),
          orderProcessedDate: formatDateISO(order.orderProcessedDate),
          snList: processedSnList,
        };
      });
  
      setdata(mergedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setloading(false);
    }
  };

function formatDateISO(dateStr: string): string {
  const date = new Date(dateStr); // Directly parse ISO date string

  // Array of month names for formatting
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const day = date.getDate(); // Get the day as a number
  const monthIndex = date.getMonth(); // Get the month index (0-based)
  const year = date.getFullYear(); // Get the full year

  const formattedDay = day.toString().padStart(2, '0'); // Ensures two digits for day
  const formattedMonth = months[monthIndex];
  const formattedYear = year.toString();

  // Return formatted date in DD MMM YYYY format
  return `${formattedDay} ${formattedMonth} ${formattedYear}`; // Formatted as "11 May 2024"
}

  const resendEmail = async (orderCode: any) => {
    console.log(emailRef.current?.input?.value, orderCode);
    try {
      const response = await fetch('https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-resend-email/admin', {
        method: 'POST',
        body: JSON.stringify({
          orderCode: orderCode,
          email: emailRef.current?.input?.value
        }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      console.log(data)
      alert('Email sent successfully')
      if (emailRef.current && emailRef.current.input) {
        emailRef.current.input.value = '';
      }
    } catch (err) {
      setErr((err as Error).message); // Set error message in state
    }
};


  const showModal = (record:any) => {
    setSelectedData(record);
    console.log(record)
    setIsModalVisible(true);
  };

  const tabonChange = (key: string) => {
    console.log(key);
  };

  useEffect(() => { 
    getData() 
  } ,[])

  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await axios.get('https://echo-assets.s3.ap-southeast-1.amazonaws.com/ac-final-mapping.json');
          setOptions(response.data);
          // console.log("data", response.data)
      } catch (error) {
          console.error('Error fetching data: ', error);
          // Handle errors here if needed
      }
  };
  fetchData();
  }, [data])

  
  const handleInputChange = (inputType: any, value: any) => {
    const trimmedValue = value.trim(); // Trim the value to remove whitespace
  
    if (inputType === 'orderNumber') {
      setOrderNumber(trimmedValue);
      setEmail('');
      setJoytelOrderNo('');
    } else if (inputType === 'email') {
      setOrderNumber('');
      setEmail(trimmedValue);
      setJoytelOrderNo('');
    } else if (inputType === 'joytelOrderNo') {
      setOrderNumber('');
      setEmail('');
      setJoytelOrderNo(trimmedValue);
    }
  
    // Check if all fields are empty, and if so, call getData
    if (!trimmedValue) {
      if (!orderNumber && !email && !joytelOrderNo) {
        getData();
      }
    }
  };

  const setSearchVar = async () => {
    setloading(true); // Set loading to true when the search starts
    try {
      let url = 'https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/ac-search?';
      if (orderNumber) {
        url += `orderNumber=${orderNumber}`;
      } else if (email) {
        url += `email=${email}`;
      } else if (joytelOrderNo) {
        url += `joytelOrderNo=${joytelOrderNo}`;
      }
  
      const res = await axios.get(url);
  
      const orders = res.data;
  
      let mergedData = orders.flatMap((order: any) => {
        const snList = Array.isArray(order.snList) ? order.snList : [];
  
        const processedSnList = snList.map((item: any) => {
          const itemSnList = Array.isArray(item.snList) ? item.snList : [];
  
          return {
            ...item,
            snList: itemSnList.map((sn: any) => ({
              ...sn,
              productCode: item.productCode,
              productName: item.productName,
            })),
          };
        });
  
        const allSnItems = snList.flatMap((item: any) => {
          const itemSnList = Array.isArray(item.snList) ? item.snList : [];
          return itemSnList.map((sn: any) => ({
            ...sn,
            productCode: item.productCode,
            productName: item.productName,
          }));
        });
  
        return {
          ...order,
          createdAt: new Date(order.createdAt).toLocaleString(),
          orderProcessedDate: formatDateISO(order.orderProcessedDate),
          snList: processedSnList,
          allSnItems: allSnItems,
        };
      });
  
      setdata(mergedData);
      setloading(false); // Set loading to false when search completes
      console.log('data', mergedData)
    } catch (error) {
      console.error('Error during search: ', error);
      message.error('No record found. Please try again.'); // Display error message
      setloading(false); // Set loading to false if there is an error
    }
  };

  type SnItem = {
    snPin: string;
    snCode: string;
    productExpireDate: string;
    productCode: string;
    productName: string;
  };
  
  type SnListEntry = {
    productCode: string;
    quantity: number;
    snList: SnItem[];
    productName: string;
  };
  
  type DataRow = {
    employeeName: string;
    itemList: {
      productCode: string;
      quantity: string;
    }[];
    orderProcessedDate: string;
    receiveName: string;
    defaultphone: string;
    oNumber: string;
    orderStatus: string;
    outboundCode: string;
    createdAt: string;
    defaultemail: string;
    snList: SnListEntry[];
    customerPhone: string;
    orderCode: string;
    orderTid: string;
    platform: string;
    updatedAt: string;
    distributor: string;
    customerEmail: string;
    allSnItems: SnItem[];
  };

  return (
      <>  
      <Row gutter={[16, 16]} align="middle">
        <Col span={5}>
          <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
            <label>Order Number</label>
            <Input 
              value={orderNumber}
              onChange={(e) => handleInputChange('orderNumber', e.target.value)}
              disabled={!!email || !!joytelOrderNo}
            />
          </div>
        </Col>
        <Col span={5}>
          <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
            <label>Email Address</label>
            <Input 
              value={email}
              onChange={(e) => handleInputChange('email', e.target.value)}
              disabled={!!orderNumber || !!joytelOrderNo}
            />
          </div>
        </Col>
        <Col span={5}>
          <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
            <label>Joytel Order Number</label>
            <Input 
              value={joytelOrderNo}
              onChange={(e) => handleInputChange('joytelOrderNo', e.target.value)}
              disabled={!!orderNumber || !!email}
            />
          </div>
        </Col>
        <Col span={5} style={{ paddingTop: "20px", textAlign: "left" }}>
          <Button onClick={setSearchVar} icon={<SearchOutlined />}>Search</Button>
        </Col>
      </Row>
          <Row>
            <Col span={5}>
              <Title level={3}>ESIM Order History</Title>
              
            </Col>
            {/* <Col span={15}>
              <Input placeholder="Search" onChange={(e) => requestSearch(e.target.value)} style={{ width: 400, marginTop:"25px", marginLeft:"10px" }} />
            </Col> */}

            <Col span={16}>
              <Button style={{float: 'right', margin: '5px', marginTop: '20px'}} type="primary" shape="round" icon={<ReloadOutlined />} onClick={getData} size="large" />
            </Col>
          </Row>
          <Table 
            columns={columns} 
            dataSource={data} 
            // scroll={{ y: 500 }} 
            loading={{ indicator: <div><Spin /></div>, spinning:loading}}
            // pagination={{ pageSize:20, defaultCurrent: 20 }}
            size="small"
            bordered
            showHeader
          />       	
          <Modal
            className='order-modal'
            width='80%'
            title="Order Details"
            visible={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            onCancel={() => setIsModalVisible(false)}
          >
            <Tabs
            onChange={tabonChange}
            type="card"
            defaultActiveKey="1"
            items={[
              {
                label: 'Order Detail',
                key: '1',
                children: (
                  <>
                    <Row gutter={16}>
                        <Col span={12}>
                          <Input
                            addonBefore="Online Order ID"
                            value={(selectedData as any)?.oNumber}
                          />
                        </Col>
                        <Col span={12}>
                          <Input
                            addonBefore="Order Processed Date"
                            value={(selectedData as any)?.orderProcessedDate}
                          />
                        </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                      <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <b>Product Info</b>
                      </Col>
                      <Col span={20}>
                        <Table columns={itemColumns} dataSource={(selectedData as any)?.itemList} size="middle" pagination={false} />
                      </Col>
                    </Row>

                    <br />
                    <Row gutter={16}>
                      <Col span={24}>
                      <Input
                            addonBefore="System Status"
                            value={(selectedData as any)?.orderStatus}
                          />
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                      <Col span={24}>
                      <Input
                            addonBefore="Submitted By"
                            value={(selectedData as any)?.employeeName}
                          />
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                      <Col span={12}>
                      <Input
                            addonBefore="Customer Phone Number"
                            value={(selectedData as any)?.customerPhone}
                          />
                      </Col>
                      <Col span={12}>
                      <Input
                            addonBefore="Customer Email"
                            value={(selectedData as any)?.customerEmail}
                          />
                      </Col>
                    </Row>
                    <br />
                    <Row gutter={16}>
                        <Col span={8}>
                          <Input
                            addonBefore="Order Code"
                            value={(selectedData as any)?.orderCode}
                          />
                        </Col>
                        <Col span={8}>
                          <Input
                            addonBefore="Order TID"
                            value={(selectedData as any)?.orderTid}
                          />
                        </Col>
                        <Col span={8}>
                          <Input
                            addonBefore="Outbound Code"
                            value={(selectedData as any)?.outboundCode}
                          />
                        </Col>
                    </Row>
                  </>
                ),
              },
              {
                label: 'Resend Email',
                key: '2',
                children: (
                  <>
                    <Input
                      value={emailRef.current?.input?.value}
                      ref={emailRef}
                      addonBefore="Email to Resend"
                    />
                    <Button 
                      type="primary" 
                      style={{marginTop: "20px"}} 
                      onClick={() => resendEmail((selectedData as any)?.orderCode)}>Resend Email
                    </Button>

                  </>
                ),
              },
              {
                label: 'Check SN No.',
                key: '3',
                children: (
                  <>
                    <br />
                    <Row gutter={16}>
                      <Col span={24}>
                        <Table columns={snColumns} dataSource={(selectedData as any)?.allSnItems} size="middle" pagination={false} />
                      </Col>
                    </Row>
                  </>
                ),
              },
              ...((selectedData as any)?.shopeeOrders?.length > 0
                ? [
                    {
                      key: '4',
                      label: 'Shopee Orders',
                      children: (
                        <>
                          <Row gutter={16}>
                            <Col span={24}>
                              <Table columns={shopeeColumns} dataSource={(selectedData as any)?.shopeeOrders || []} pagination={false} />
                            </Col>
                          </Row>
                        </>
                      ),
                    },
                  ]
                : []),
            ]}
          />
        </Modal>
      </>
  );

}
