import React, { useState, useEffect } from "react";
import { Button, Radio, Space, Spin, message, Typography } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

interface TopUp {
  Description: string;
  TopUpValue: number;
}

interface Bundle {
  BundleName: string;
  BundleValue: number;
  BundleProductCode: string;
}

interface ApiResponse {
  TopUpList: TopUp[];
  BundleList: Bundle[];
  NetworkName: string;
  Status: number;
  Message: string;
}

interface LocationState {
  networkName: string;
  phoneNumber: string;
  simSerialNumber?: string;
}

interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

const { Text } = Typography;

export default function TopUpSelection() {
  const [topUpList, setTopUpList] = useState<TopUp[]>([]);
  const [bundleList, setBundleList] = useState<Bundle[]>([]);
  const [filteredTopUpList, setFilteredTopUpList] = useState<TopUp[]>([]);
  const [filteredBundleList, setFilteredBundleList] = useState<Bundle[]>([]);
  const [selectedOption, setSelectedOption] = useState<
    | { type: "topup"; value: number | string }
    | { type: "bundle"; BundleName: string; BundleProductCode: string; BundleValue: string }
    | null
  >(null);
  const [loading, setLoading] = useState(true);
  const [networkName, setNetworkName] = useState<string>("");
  const [token, setToken] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;
  const {
    networkName: selectedNetworkName,
    phoneNumber,
    simSerialNumber,
  } = state;

  // Function to filter top-up and bundle values based on network
  const filterValues = (networkName: string, topUpList: TopUp[], bundleList: Bundle[]) => {
    let filteredTopUps: TopUp[] = [];
    let filteredBundles: Bundle[] = [];
  
    if (networkName === "3") {
      filteredTopUps = topUpList.filter((topUp) => [10, 15, 20, 35].includes(topUp.TopUpValue));
      filteredBundles = bundleList.filter((bundle) => [10, 15, 20, 35].includes(bundle.BundleValue));
    } else if (networkName === "Vodafone") {
      filteredTopUps = topUpList.filter((topUp) => [10, 15, 20, 30].includes(topUp.TopUpValue));
      filteredBundles = bundleList.filter((bundle) => [10, 20, 30].includes(bundle.BundleValue));
    } else if (networkName === "O2") {
      filteredTopUps = topUpList.filter((topUp) => [10, 15, 30].includes(topUp.TopUpValue));
      filteredBundles = bundleList; // No bundle restrictions for O2
    } else {
      filteredTopUps = topUpList; // Default case, no filtering
      filteredBundles = bundleList;
    }
  
    // Sort both lists in ascending order by value
    filteredTopUps.sort((a, b) => a.TopUpValue - b.TopUpValue);
    filteredBundles.sort((a, b) => a.BundleValue - b.BundleValue);
  
    setFilteredTopUpList(filteredTopUps);
    setFilteredBundleList(filteredBundles);
  };

  const fetchToken = async (): Promise<string> => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    urlencoded.append("username", "ECH100");
    urlencoded.append("password", "Echonet8888!");
    urlencoded.append("client_id", "ffd23f9a-3ec0-4118-b5ab-16dd92706db0");
    urlencoded.append("client_secret", "SaqngdzMzZkxzK8X4ltFws9rdOjifNecVH45IaD9XbxfnU0Dzd");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow" as RequestRedirect,
    };

    try {
      const response = await fetch("https://acrp.shukran.tech/em/GenerateToken", requestOptions);
      if (!response.ok) {
        throw new Error('Failed to fetch token');
      }
      const result = await response.json();
      console.log(urlencoded);
      return result.access_token;
    } catch (error) {
      console.error('Error fetching token:', error);
      throw error;
    }
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      try {
        const accessToken = await fetchToken();
        setToken(accessToken);

        const apiEndpoint = "https://acrp.shukran.tech/em/ConfirmContact";

        const requestBody = {
          NetworkName: selectedNetworkName,
          ContactNumber: phoneNumber,
          SIMSerialNumber: simSerialNumber || "",
        };

        const response = await fetch(apiEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(requestBody),
        });

        const data: ApiResponse = await response.json();

        if (data.Status === 1) {
          setTopUpList(data.TopUpList);
          setBundleList(data.BundleList);
          setNetworkName(data.NetworkName);

          // Apply filtering based on the network name
          filterValues(selectedNetworkName, data.TopUpList, data.BundleList);
        } else {
          message.error(data.Message || "Failed to fetch data from API.");
        }
      } catch (error) {
        message.error("Failed to fetch data from API.");
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [selectedNetworkName, phoneNumber, simSerialNumber]);

  const handleOptionChange = (type: "topup" | "bundle", value: number | string) => {
    if (type === "bundle") {
      const selectedBundle = bundleList.find(bundle => bundle.BundleName === value);
      
      if (selectedBundle) {
        const updatedOption = {
          type,
          BundleName: selectedBundle.BundleName,
          BundleProductCode: selectedBundle.BundleProductCode,
          BundleValue: selectedBundle.BundleValue.toString(), // Ensure BundleValue is a string
        };
        setSelectedOption(updatedOption);
        console.log("Selected option:", updatedOption);  // Logs updated bundle details
      }
    } else {
      const updatedOption = { type, value };
      setSelectedOption(updatedOption);
      console.log("Selected option:", updatedOption);  // Logs top-up details
    }
  };

  const handleContinue = () => {
    if (!selectedOption) {
      message.error("Please select a top-up amount or bundle.");
      return;
    }
  
    let topUpValue = "";
    let bundleProductCode = "";
    let bundleValue = "";
  
    // Determine values based on selected option type
    if (selectedOption.type === "topup") {
      topUpValue = selectedOption.value.toString();
    } else if (selectedOption.type === "bundle") {
      bundleProductCode = /^[0-9]+$/.test(selectedOption.BundleProductCode)
        ? selectedOption.BundleProductCode
        : "";
      bundleValue = selectedOption.BundleValue;
    }
  
    console.log("Selected Bundle Code:", bundleProductCode);
  
    navigate("/confirmation", {
      state: {
        phoneNumber,
        selectedOption,
        networkName: networkName || selectedNetworkName,
        simSerialNumber: simSerialNumber || "",
        topUpValue,                 // Set only if top-up selected
        bundleProductCode,          // Set only if bundle selected
        bundleValue,                // Set only if bundle selected
      },
    });
  };


  return (
    <div style={{ textAlign: "center" }}>
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
          <h1>{`You have selected ${networkName}`}</h1>
          <p>
            <Text strong>{`Phone Number: `}</Text>
            <Text strong style={{ fontSize: "18px" }}>
              {phoneNumber}
            </Text>
          </p>

          <h2>Select top-up amount</h2>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Radio.Group
              value={selectedOption?.type === "topup" ? selectedOption.value : null}
              onChange={(e) => handleOptionChange("topup", e.target.value)}
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "600px",
                margin: "0 auto",
              }}
            >
              {filteredTopUpList.map((topup) => (
                <Radio.Button
                  key={topup.TopUpValue}
                  value={topup.TopUpValue}
                  style={{
                    width: "100%",
                    height: "70px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    fontSize: "20px",
                    lineHeight: "1.2",
                    textAlign: "center",
                    borderRadius: "5px",
                    borderWidth: "3px",
                  }}
                >
                  <div>{`£${topup.TopUpValue.toFixed(2)}`}</div>
                </Radio.Button>
              ))}
            </Radio.Group>

            {filteredBundleList.length > 0 && (
              <>
                <h2>Or select a bundle from below</h2>
                <Radio.Group
                  value={selectedOption?.type === "bundle" ? selectedOption.BundleName : null}
                  onChange={(e) => handleOptionChange("bundle", e.target.value)}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
                    gap: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  {filteredBundleList.map((bundle) => (
                    <Radio.Button
                      key={bundle.BundleProductCode}
                      value={bundle.BundleName}
                      style={{
                        width: "100%",
                        height: "100px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "5px",
                        fontSize: "20px",
                        lineHeight: "1.2",
                        textAlign: "center",
                        borderRadius: "5px",
                        borderWidth: "3px",
                      }}
                    >
                      <div>{bundle.BundleName}</div>
                      <div style={{ marginTop: "10px" }}>{`£${bundle.BundleValue.toFixed(2)}`}</div>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </>
            )}
          </Space>

          <div style={{ marginTop: 20 }}>
            <Button type="primary" onClick={handleContinue} disabled={!selectedOption}>
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );
}