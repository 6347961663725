import React, { useState, useEffect } from 'react';
import { Input, Button, message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

interface Network {
  id: number;
  name: string;
  imgSrc: string;
  topupTypes: string[];
}

interface LocationState {
  networkName: string;
  phoneNumber: string;
  simSerialNumber?: string;
  imgSrc: string;  // Add imgSrc to the interface
}

export default function PhoneNumberInput() {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true); // Use a new state for button disabled
  const navigate = useNavigate();
  const location = useLocation();  
  const state = location.state as LocationState;  
  const { networkName, imgSrc } = state;  // Retrieve networkName and imgSrc

  useEffect(() => {
    // Update button state whenever phoneNumber or confirmPhoneNumber changes
    if (phoneNumber && confirmPhoneNumber && phoneNumber === confirmPhoneNumber) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [phoneNumber, confirmPhoneNumber]);

  const handleContinue = () => {
    if (phoneNumber === confirmPhoneNumber) {
      navigate('/topup', { state: { networkName, phoneNumber } });
    } else {
      message.error('Phone numbers do not match. Please try again.');
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{`You have selected ${networkName}`}</h1>

      {/* Display the logo */}
      <div>
        <img src={imgSrc} alt={`${networkName} logo`} style={{ height: '100px', marginBottom: '20px' }} />
      </div>

      <p>Please enter the mobile number you wish to top up:</p>

      <Input
        className="dark-placeholder"
        style={{ width: 300, marginBottom: 10 }}
        placeholder="Enter Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      <br />
      <Input
        className="dark-placeholder"
        style={{ width: 300 }}
        placeholder="Confirm Phone Number"
        value={confirmPhoneNumber}
        onChange={(e) => setConfirmPhoneNumber(e.target.value)}
      />

      <div style={{ marginTop: 20 }}>
        <Button 
          type="primary" 
          onClick={handleContinue} 
          disabled={isButtonDisabled}  // Use the isButtonDisabled state
        >
          Continue
        </Button>
      </div>
    </div>
  );
};