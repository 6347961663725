import { useEffect, useRef, useState } from "react";
import { Table, Input, Button, Spin, Row, Col, Typography, Tag } from "antd";
import type { InputRef } from "antd";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import { formatDateISO } from "../../../utils/date";
import InventoryModal from "./InventoryModal";
const { Text } = Typography;

const SB_DATA_MAPPING_INVENTORY_PROD_URL =
  "https://gr5l78aozj.execute-api.ap-southeast-1.amazonaws.com/prod/sb-data-mapping/inventory";
const SB_DATA_MAPPING_INVENTORY_DEV_URL =
  "https://wv90e9zk2m.execute-api.ap-southeast-1.amazonaws.com/test/sb-data-mapping/inventory";

const sbDataMappingHistoryUrl =
  process.env.NODE_ENV === "production" ||
  process.env.VERCEL_ENV === "production"
    ? SB_DATA_MAPPING_INVENTORY_PROD_URL
    : SB_DATA_MAPPING_INVENTORY_DEV_URL;

export interface SimCard {
  orderProcessedDate: string; // Format: "YYYY-MM-DD HH:MM:SS"
  orderProcessedDateISO: string | undefined; // Format: "YYYY-MM-DD HH:MM:SS"
  dateTime: string; // Format with milliseconds: "YYYY-MM-DD HH:MM:SS.mmmmmm"
  status: string;
  comments: string;
  name: string;
  simNo: string;
  confirmationCode: string;
  ldapImageUrl: string;
  activationCodeUri: string;
  orderNumber: string;
  expiryDate: string; // Format: "YYYY-MM-DD"
  activationCode: string;
  smDpAddress: string;
  snNo: string;
  productCode: string;
  customerEmail: string;
}

export default function SBHistory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSim, setSelectedSim] = useState<SimCard | null>(null);
  const { Title } = Typography;
  const emailRef = useRef<InputRef>(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (search === "") {
      getData();
    }
  }, [search]);

  async function getData() {
    let searchQueryStr = "";
    if (search.length > 0) {
      searchQueryStr = `?search=${search}`;
    }
    const res = await axios.get(sbDataMappingHistoryUrl + searchQueryStr);
    const formattedData = res.data.map((order: SimCard) => {
      return {
        ...order,
        orderProcessedDateISO: order.orderProcessedDate,
        orderProcessedDate: formatDateISO(order.orderProcessedDate),
      };
    });
    const orders = formattedData;
    setLoading(false);
    setData(orders);
  };

  function handleOpenModal(sim: SimCard) {
    setSelectedSim(sim);
    setIsModalVisible(true);
  }

  /**
   * Configure columns for table
   */
  const columns: ColumnsType<SimCard> = [
    {
      title: "Order Date",
      dataIndex: "orderProcessedDate",
      key: "orderProcessedDate",
      width: 90,
      sorter: (a, b) =>
        a.orderProcessedDateISO?.localeCompare(b.orderProcessedDateISO!!) ?? -1,
      render: (date: string) => date === "" ? "-" : date,
    },
    {
      title: "Online Order Number",
      dataIndex: "orderNumber",
      key: "oNumber",
      width: 100,
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
      render: (orderNumber: string) => orderNumber === "" ? "-" : orderNumber,
    },
    {
      title: "Order Status",
      dataIndex: "status",
      key: "orderStatus",
      width: 120,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status: string) => {
        let color = "volcano"; // Default to red for failed jobs
        switch (status) {
          case "USED/EMAIL SENT":
          case "USED":
            color = "blue";
            break;
          case "":
            color = "green";
            status = "AVAILABLE";
            break;
          default:
            color = "volcano";
        }

        return <Tag color={color}>{status}</Tag>;
      },
    },
    {
      title: "Customer Email",
      dataIndex: "customerEmail",
      key: "customerEmail",
      width: 100,
      sorter: (a, b) => a.customerEmail.localeCompare(b.customerEmail),
      render: (email: string) => email === "" ? "-" : email,
    },
    {
      title: "SIM Number",
      width: 100,
      dataIndex: "simNo",
      key: "simNo",
      sorter: (a, b) => a.simNo.localeCompare(b.simNo),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <>
          <Button type="primary" onClick={() => handleOpenModal(record)}>
            View
          </Button>
          <Button href={record.ldapImageUrl} style={{ marginLeft: "10px" }}>
            Download QR
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" gutter={8}>
        <Col flex="auto">
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value.trim())}
            placeholder="Search all fields..."
            onPressEnter={getData}
          />
        </Col>
        <Col>
          <Button onClick={getData} icon={<SearchOutlined />}>
            Search
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <Title level={3}>Slowboat Inventory</Title>
        </Col>

        <Col span={9} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{ float: "right", margin: "5px", marginTop: "20px" }}
            type="primary"
            shape="round"
            icon={<ReloadOutlined />}
            onClick={getData}
            size="large"
          />
        </Col>
      </Row>
      <Row>
        <Text>{loading ? "Loading..." : `Total: ${data.length} records`}</Text>
      </Row>
      <Table
        columns={columns}
        dataSource={data}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: loading,
        }}
        pagination={{
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 50, 100],
          defaultCurrent: 1
        }}
        size="small"
        bordered
        showHeader
      />

      <InventoryModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        sim={selectedSim}
      />
    </>
  );
}
